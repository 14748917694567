<template>
  <div>
    <div style="positiion: relative">
      <v-navigation-drawer v-model="drawerdata" fixed temporary clipped width="350px">
        <div class="productFilter-content ma-auto">
          <div class="px-6 py-2 productFilter-category-title d-block">
            <table>
              <tr>
                <td><v-icon v-text="'$categoryIcon'" class="d-inline-block"></v-icon></td>
                <td>
                  <div class="font-weight-bold d-inline-block text-subtitle-1 pl-3">{{ $t(`label.categories`) }}</div>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <v-treeview
              :open="initiallyOpen"
              :active="activeType"
              color="unbox_primary"
              class="pl-6 cursor-pointer"
              activatable
              :items="storageProductTypeTreeview"
              item-children="chidren"
              item-key="uuid"
              dense
              expand-icon="$chevronDownIcon"
              @update:active="updateFilter"
              active-class="productFilter-tree-selected"
            >
              <template v-slot:label="{ item }">
                <v-btn plain text @click="filter.type == item.uuid ? (filter.type = '') : (filter.type = item.uuid)" class="text-body-2 text-capitalize" :ripple="false">
                  {{ item.text }}
                </v-btn>
              </template>
            </v-treeview>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="my-5">
            <div class="text-body-2 my-2 font-weight-bold">{{ $t(`label.price`) }}</div>
            <v-row justify="center" align="center" no-gutters class="mb-3">
              <v-col cols="5">
                <v-text-field class="text-body-2" dense outlined v-model="filter.prices[0]" hide-details prefix="RM"></v-text-field>
              </v-col>
              <v-col cols="2"><div class="text-center">-</div></v-col>
              <v-col cols="5">
                <v-text-field class="text-body-2" dense outlined v-model="filter.prices[1]" hide-details prefix="RM"></v-text-field>
              </v-col>
            </v-row>
            <v-btn block class="rounded-lg font-weight-bold white--text text-body-2 py-4" color="unbox_black" @click="updateFilter">{{ $t(`label.apply`) }}</v-btn>
          </div>
          <v-divider class="my-4"></v-divider>
          <v-expansion-panels flat v-model="brandExpansionModel">
            <v-expansion-panel>
              <v-expansion-panel-header color="light_gray" class="pa-2">
                <div class="text-body-2 font-weight-bold">{{ $t(`label.brands`) }}</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="light_gray">
                <div v-for="brand in productBrands" :key="brand.value">
                  <v-checkbox dense on-icon="$checkIcon" v-model="filter.brands" :value="brand.uuid" hide-details :ripple="false" @change="updateFilter">
                    <template v-slot:label>
                      <span class="text-capitalize text-body-2 unbox_black--text">{{ brand.text }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-divider class="my-4"></v-divider>
          <v-btn block class="rounded-lg font-weight-bold white--text text-body-2 py-4" color="unbox_black" @click="clearFilter">{{ $t(`label.clearAllFilters`) }}</v-btn>
        </div>
      </v-navigation-drawer>
    </div>
    <div class="ma-3 mx-sm-6 ms-2">
      <table class="my-10 productShop-container-table full-width">
        <tr>
          <td class="productFilter-container d-lg-table-cell d-none">
            <div class="productFilter-content">
              <div class="px-6 py-2 productFilter-category-title d-block">
                <table>
                  <tr>
                    <td><v-icon v-text="'$categoryIcon'" class="d-inline-block"></v-icon></td>
                    <td>
                      <div class="font-weight-bold d-inline-block text-subtitle-1 pl-3">{{ $t(`label.categories`) }}</div>
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <v-treeview
                  :open="initiallyOpen"
                  :active="activeType"
                  color="unbox_primary"
                  class="pl-6 cursor-pointer"
                  activatable
                  :items="storageProductTypeTreeview"
                  item-children="chidren"
                  item-key="uuid"
                  dense
                  expand-icon="$chevronDownIcon"
                  @update:active="updateFilter"
                  selection-type="independent"
                  active-class="productFilter-tree-selected"
                >
                  <template v-slot:label="{ item }">
                    <v-btn plain text @click="filter.type == item.uuid ? (filter.type = '') : (filter.type = item.uuid)" class="text-body-2 text-capitalize" :ripple="false">
                      {{ item.text }}
                    </v-btn>
                  </template>
                </v-treeview>
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="my-5">
                <div class="text-body-2 my-2 font-weight-bold">{{ $t(`label.price`) }}</div>

                <v-form @submit.prevent="updateFilter">
                  <v-row justify="center" align="center" no-gutters class="mb-4">
                    <v-col cols="5">
                      <v-text-field class="text-body-2" dense outlined v-model="filter.prices[0]" hide-details prefix="RM" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="2"><div class="text-center">-</div></v-col>
                    <v-col cols="5">
                      <v-text-field class="text-body-2" dense outlined v-model="filter.prices[1]" hide-details prefix="RM" type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn type="submit" block class="rounded-lg font-weight-bold white--text text-body-2 py-4" color="unbox_black" @click="updateFilter">{{ $t(`label.apply`) }}</v-btn>
                </v-form>
              </div>
              <v-divider class="my-4"></v-divider>
              <v-expansion-panels flat v-model="brandExpansionModel">
                <v-expansion-panel>
                  <v-expansion-panel-header color="light_gray" class="pa-2">
                    <div class="text-body-2 font-weight-bold">{{ $t(`label.brands`) }}</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="light_gray">
                    <div v-for="brand in productBrands" :key="brand.value">
                      <v-checkbox dense on-icon="$checkIcon" v-model="filter.brands" :value="brand.uuid" hide-details :ripple="false" @change="updateFilter">
                        <template v-slot:label>
                          <span class="text-capitalize text-body-2 unbox_black--text">{{ brand.text }}</span>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-divider class="my-4"></v-divider>
              <v-btn block class="rounded-lg font-weight-bold white--text text-body-2 py-4" color="unbox_black" @click="clearFilter">{{ $t(`label.clearAllFilters`) }}</v-btn>
            </div>
          </td>
          <td>
            <div class="mb-8 d-lg-none d-md-block">
              <v-btn class="font-weight-bold" color="white" @click="openFilterDrawer">
                <v-icon class="stroke--unbox_primary pr-2" v-text="'$filterIcon'"></v-icon>
                {{ $t(`label.sideFilter`) }}
              </v-btn>
            </div>
            <div cols="12" class="text-right mb-sm-8 mb-4 mt-sm-0 mt-4">
              <v-select v-model="filter.orderBy" outlined :items="orderByItem" dense class="text-body-2 sortBy-selection" :label="$t(`label.sortBy`)" @change="updateFilterSortBy"></v-select>
            </div>
            <v-row v-if="products != null && products.length <= 0" no-gutters>
              <v-col cols="12" class="mt-16 pt-16 text-center productContent-noResult-box">
                <div class="text-h5 d-block my-2">{{ $t(`label.searchNoResult`) }}</div>
                <div class="text-h6 my-2">{{ $t(`label.noMatchSearchTerm`) }}</div>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col xl="3" lg="3" md="4" cols="6" v-for="product in products" :key="product.group">
                <v-hover v-slot="{ hover }">
                  <v-card class="mx-2" :elevation="hover ? 10 : 0" @click="goToProductDetailPage(product)">
                    <div class="px-sm-6 px-0 pt-2 pb-6 mb-3 mx-2 cursor-pointer">
                      <img class="full-width" :src="product.gallery[0]" :alt="product.name" :title="product.name" />
                      <div class="mt-6">
                        <div :class="product.highestDiscountedRate != 0 ? 'd-visible' : 'd-invisible'">
                          <v-row align="center" no-gutters justify="space-between">
                            <v-col cols="5">
                              <div class="text-caption text-decoration-line-through text_light_gray--text">
                                {{ $shared.formatCurrency(product.lowestSellingPrice) }}
                              </div>
                            </v-col>
                            <v-col cols="4" md="4" lg="6" xl="3" :class="product.highestDiscountedRate >= 3 ? 'd-visible' : 'd-invisible'">
                              <div class="productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text">
                                <span class="white--text text-caption text-uppercase font-weight-bold">{{ $t(`label.upTo`) }} {{ product.highestDiscountedRate }}%</span>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="text-body-2 unbox_black--text productContent-name mt-2">{{ product.name }}</div>
                        <div class="text-sm-h5 text-h6 font-weight-black unbox_red--text">
                          <span v-if="product.discountRate == 0">{{ $shared.formatCurrency(product.lowestSellingPrice) }}</span>
                          <span v-else>{{ $shared.formatCurrency(product.lowestDiscountedPrice) }}</span>
                        </div>
                        <div class="text-caption text_light_gray--text productContent-description mt-1">{{ product.description }}</div>
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12">
                <v-pagination color="unbox_primary" v-model="filter.page" :length="paoductPagination.lastPage" :total-visible="7" @input="updateFilterPage"></v-pagination>
              </v-col>
            </v-row>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { PRODUCT_PRODUCTS } from '@/store/product.module'
import { storageHelper, sharedHelper, localeHelper } from '@/utils'
import { CACHE_KEY, SHARED, SESSION } from '@/constants'
import { PRODUCT_STATUS } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'Shop',
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    const isNotShopRelatedPage = ![ROUTE_NAME.SHOP, ROUTE_NAME.SHOP_PRODUCT].includes(to.name)
    if (isNotShopRelatedPage) {
      localStorage.removeItem(SESSION.SHOP_SEARCH)
    }

    next()
  },
  data: () => ({
    isSearchNewReleaseProduct: false,
    drawerdata: false,
    brandExpansionModel: 0,
    shared: SHARED,
    storageProductTypeTreeview: storageHelper.getLocalStorage(`${CACHE_KEY.PRODUCT_TYPE_TREEVIEW}`),
    storageProductType: storageHelper.getLocalStorage(`${CACHE_KEY.PRODUCT_TYPE}`),
    activeType: [],
    initiallyOpen: [],
    minPriceRange: 0,
    maxPriceRange: 20000,
    filter: {
      keyword: '',
      brands: [],
      prices: [0, 20000],
      type: '',
      page: 1,
      size: 20,
      orderBy: 'latest',
      sortBy: 'created_date_time'
    },
    orderByItem: [
      {
        text: localeHelper.getMessage('label.productSortByLatest'),
        value: 'latest'
      },
      {
        text: localeHelper.getMessage('label.priceFromLowToHigh'),
        value: 'asc'
      },
      {
        text: localeHelper.getMessage('label.priceFromHighToLow'),
        value: 'desc'
      }
    ]
  }),
  computed: {
    productBrands() {
      return this.$store.state.lookup.productBrands
    },
    productTypesTreeView() {
      return this.$store.state.lookup.productTypesTreeView
    },
    productTypes() {
      return this.$store.state.lookup.productTypes
    },
    products() {
      return this.$store.state.product.products.data
    },
    paoductPagination() {
      return this.$store.state.product.products.pagination
    }
  },
  watch: {
    productTypesTreeView() {
      this.storageProductTypeTreeview = this.$store.state.lookup.productTypesTreeView
    },
    productTypes() {
      this.storageProductType = this.$store.state.lookup.productTypes
    },
    $route(from, to) {
      this.checkFiltering()
    }
  },
  created() {
    this.isSearchNewReleaseProduct = this.$route.params.preOrder
    this.checkFiltering()
  },
  methods: {
    updatePageHistory() {
      let shopSearchCriteria = sharedHelper.getShopSearchFilter()
      this.$router
        .push({
          name: ROUTE_NAME.SHOP,
          query: shopSearchCriteria,
          params: {
            preOrder: this.isSearchNewReleaseProduct
          }
        })
        .catch(() => {})
    },
    updateFilterPage(page) {
      this.filter.page = page
      sharedHelper.setShopSearchFilter('page', `${this.filter.page}`)
      this.updatePageHistory()
    },
    updateFilterSortBy() {
      sharedHelper.setShopSearchFilter('orderBy', `${this.filter.orderBy}`)
      sharedHelper.setShopSearchFilter('sortBy', `${this.filter.sortBy}`)
      this.updatePageHistory()
    },
    updateFilter() {
      this.filter.keyword = this.$root.$master.searchKeyword
      this.filter.page = 1
      this.drawerdata = false

      let category = this.filter.type
      if (category != undefined && category != '') {
        let categoryInfo = this.storageProductType.find((x) => x.id == category)
        sharedHelper.setShopSearchFilter('category', `${categoryInfo.text.toLowerCase().replace(/\s+/g, '-')}-${SHARED.PRODUCT_TYPE_PREFIX}${category}`)
      } else {
        sharedHelper.setShopSearchFilter('category', ``)
      }
      if (this.filter.brands != null && this.filter.brands.length > 0) {
        sharedHelper.setShopSearchFilter('brand', `${this.filter.brands.join(',')}`)
      } else {
        sharedHelper.setShopSearchFilter('brand', ``)
      }

      sharedHelper.setShopSearchFilter('keyword', `${this.filter.keyword == null ? '' : this.filter.keyword}`)
      sharedHelper.setShopSearchFilter('price', `${this.filter.prices.join(',')}`)

      this.isSearchNewReleaseProduct = false
      this.updatePageHistory()
    },
    clearFilter() {
      this.filter.page = 1
      this.filter.keyword = ''
      this.filter.brands = []
      this.filter.prices = [0, 20000]
      this.filter.type = []
      this.filter.orderBy = 'latest'
      this.filter.sortBy = 'created_date_time'
      this.drawerdata = false
      this.getProduct()
    },
    openFilterDrawer() {
      this.drawerdata = !this.drawerdata
    },
    checkFiltering() {
      let category = this.$route.query.category?.split(SHARED.PRODUCT_TYPE_PREFIX)[1] ?? undefined
      if (category != undefined) {
        this.filter.type = category
        this.activeType = [category]
        let parentId = sharedHelper.getParent(this.storageProductTypeTreeview, category)
        this.initiallyOpen = parentId.split('.')
      } else {
        this.filter.type = ''
        this.activeType = []
      }

      let brand = this.$route.query.brand
      if (!(brand == '' || brand == undefined)) {
        this.filter.brands = brand.split(',')
      }

      let price = this.$route.query.price
      if (!(price == '' || price == undefined)) {
        this.filter.prices = price.split(',')
      }

      let keyword = this.$route.query.keyword
      this.filter.keyword = keyword == undefined ? '' : keyword

      let orderBy = this.$route.query.orderBy
      this.filter.orderBy = orderBy == undefined ? 'latest' : orderBy
      this.filter.sortBy = orderBy == undefined || orderBy == 'latest' ? 'created_date_time' : 'price'

      this.getProduct()
    },
    getProduct() {
      let filter = this.filter

      if (this.isSearchNewReleaseProduct) {
        filter['status'] = PRODUCT_STATUS.NEW_RELEASE
      } else {
        filter['status'] = undefined
      }
      this.$store.dispatch(PRODUCT_PRODUCTS, { filter })
    },
    goToProductDetailPage(product) {
      let productName = product.name.replace(/\s+/g, '-').toLowerCase()
      this.$router.push({
        name: ROUTE_NAME.SHOP_PRODUCT,
        params: {
          productName: `${productName}-${SHARED.PRODUCT_GROUP_PREFIX}${product.group}`,
          preOrder: this.isSearchNewReleaseProduct
        }
      })
    }
  }
}
</script>

<style lang="scss">
.productFilter-content {
  width: 90%;
}

.productFilter-category-title {
  background-color: var(--v-light_gray-base);
}
.productFilter-container {
  width: 350px;
}
.productFilter-tree-selected {
  background-color: rgba(199, 168, 126, 0.2);
}

.productShop-container-table td {
  vertical-align: top;
}

.productContent-name {
  height: 60px;
  overflow: hidden;
}
.productContent-description {
  height: 40px;
  overflow: hidden;
}

.productContent-discountRate {
  background-color: var(--v-sharp_red-base);
}
.productContent-noResult-box {
  height: 500px;
}

.sortBy-selection {
  width: 100%;
  max-width: 300px;
}
</style>
