var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { positiion: "relative" } },
      [
        _c(
          "v-navigation-drawer",
          {
            attrs: { fixed: "", temporary: "", clipped: "", width: "350px" },
            model: {
              value: _vm.drawerdata,
              callback: function ($$v) {
                _vm.drawerdata = $$v
              },
              expression: "drawerdata",
            },
          },
          [
            _c(
              "div",
              { staticClass: "productFilter-content ma-auto" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "px-6 py-2 productFilter-category-title d-block",
                  },
                  [
                    _c("table", [
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c("v-icon", {
                              staticClass: "d-inline-block",
                              domProps: {
                                textContent: _vm._s("$categoryIcon"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold d-inline-block text-subtitle-1 pl-3",
                            },
                            [_vm._v(_vm._s(_vm.$t("label.categories")))]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c("v-treeview", {
                      staticClass: "pl-6 cursor-pointer",
                      attrs: {
                        open: _vm.initiallyOpen,
                        active: _vm.activeType,
                        color: "unbox_primary",
                        activatable: "",
                        items: _vm.storageProductTypeTreeview,
                        "item-children": "chidren",
                        "item-key": "uuid",
                        dense: "",
                        "expand-icon": "$chevronDownIcon",
                        "active-class": "productFilter-tree-selected",
                      },
                      on: { "update:active": _vm.updateFilter },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-body-2 text-capitalize",
                                  attrs: { plain: "", text: "", ripple: false },
                                  on: {
                                    click: function ($event) {
                                      _vm.filter.type == item.uuid
                                        ? (_vm.filter.type = "")
                                        : (_vm.filter.type = item.uuid)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.text) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "my-4" }),
                _c(
                  "div",
                  { staticClass: "my-5" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-body-2 my-2 font-weight-bold" },
                      [_vm._v(_vm._s(_vm.$t("label.price")))]
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "mb-3",
                        attrs: {
                          justify: "center",
                          align: "center",
                          "no-gutters": "",
                        },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("v-text-field", {
                              staticClass: "text-body-2",
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                prefix: "RM",
                              },
                              model: {
                                value: _vm.filter.prices[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.prices, 0, $$v)
                                },
                                expression: "filter.prices[0]",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "2" } }, [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v("-"),
                          ]),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("v-text-field", {
                              staticClass: "text-body-2",
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                prefix: "RM",
                              },
                              model: {
                                value: _vm.filter.prices[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.prices, 1, $$v)
                                },
                                expression: "filter.prices[1]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "rounded-lg font-weight-bold white--text text-body-2 py-4",
                        attrs: { block: "", color: "unbox_black" },
                        on: { click: _vm.updateFilter },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.apply")))]
                    ),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "my-4" }),
                _c(
                  "v-expansion-panels",
                  {
                    attrs: { flat: "" },
                    model: {
                      value: _vm.brandExpansionModel,
                      callback: function ($$v) {
                        _vm.brandExpansionModel = $$v
                      },
                      expression: "brandExpansionModel",
                    },
                  },
                  [
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass: "pa-2",
                            attrs: { color: "light_gray" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-body-2 font-weight-bold" },
                              [_vm._v(_vm._s(_vm.$t("label.brands")))]
                            ),
                          ]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          { attrs: { color: "light_gray" } },
                          _vm._l(_vm.productBrands, function (brand) {
                            return _c(
                              "div",
                              { key: brand.value },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    dense: "",
                                    "on-icon": "$checkIcon",
                                    value: brand.uuid,
                                    "hide-details": "",
                                    ripple: false,
                                  },
                                  on: { change: _vm.updateFilter },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-capitalize text-body-2 unbox_black--text",
                                              },
                                              [_vm._v(_vm._s(brand.text))]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.filter.brands,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "brands", $$v)
                                    },
                                    expression: "filter.brands",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "my-4" }),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "rounded-lg font-weight-bold white--text text-body-2 py-4",
                    attrs: { block: "", color: "unbox_black" },
                    on: { click: _vm.clearFilter },
                  },
                  [_vm._v(_vm._s(_vm.$t("label.clearAllFilters")))]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "ma-3 mx-sm-6 ms-2" }, [
      _c(
        "table",
        { staticClass: "my-10 productShop-container-table full-width" },
        [
          _c("tr", [
            _c(
              "td",
              { staticClass: "productFilter-container d-lg-table-cell d-none" },
              [
                _c(
                  "div",
                  { staticClass: "productFilter-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-6 py-2 productFilter-category-title d-block",
                      },
                      [
                        _c("table", [
                          _c("tr", [
                            _c(
                              "td",
                              [
                                _c("v-icon", {
                                  staticClass: "d-inline-block",
                                  domProps: {
                                    textContent: _vm._s("$categoryIcon"),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold d-inline-block text-subtitle-1 pl-3",
                                },
                                [_vm._v(_vm._s(_vm.$t("label.categories")))]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c("v-treeview", {
                          staticClass: "pl-6 cursor-pointer",
                          attrs: {
                            open: _vm.initiallyOpen,
                            active: _vm.activeType,
                            color: "unbox_primary",
                            activatable: "",
                            items: _vm.storageProductTypeTreeview,
                            "item-children": "chidren",
                            "item-key": "uuid",
                            dense: "",
                            "expand-icon": "$chevronDownIcon",
                            "selection-type": "independent",
                            "active-class": "productFilter-tree-selected",
                          },
                          on: { "update:active": _vm.updateFilter },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-body-2 text-capitalize",
                                      attrs: {
                                        plain: "",
                                        text: "",
                                        ripple: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.filter.type == item.uuid
                                            ? (_vm.filter.type = "")
                                            : (_vm.filter.type = item.uuid)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.text) + " ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "my-4" }),
                    _c(
                      "div",
                      { staticClass: "my-5" },
                      [
                        _c(
                          "div",
                          { staticClass: "text-body-2 my-2 font-weight-bold" },
                          [_vm._v(_vm._s(_vm.$t("label.price")))]
                        ),
                        _c(
                          "v-form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.updateFilter.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  justify: "center",
                                  align: "center",
                                  "no-gutters": "",
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "text-body-2",
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        "hide-details": "",
                                        prefix: "RM",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.filter.prices[0],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filter.prices, 0, $$v)
                                        },
                                        expression: "filter.prices[0]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "2" } }, [
                                  _c("div", { staticClass: "text-center" }, [
                                    _vm._v("-"),
                                  ]),
                                ]),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "text-body-2",
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        "hide-details": "",
                                        prefix: "RM",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.filter.prices[1],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filter.prices, 1, $$v)
                                        },
                                        expression: "filter.prices[1]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "rounded-lg font-weight-bold white--text text-body-2 py-4",
                                attrs: {
                                  type: "submit",
                                  block: "",
                                  color: "unbox_black",
                                },
                                on: { click: _vm.updateFilter },
                              },
                              [_vm._v(_vm._s(_vm.$t("label.apply")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "my-4" }),
                    _c(
                      "v-expansion-panels",
                      {
                        attrs: { flat: "" },
                        model: {
                          value: _vm.brandExpansionModel,
                          callback: function ($$v) {
                            _vm.brandExpansionModel = $$v
                          },
                          expression: "brandExpansionModel",
                        },
                      },
                      [
                        _c(
                          "v-expansion-panel",
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                staticClass: "pa-2",
                                attrs: { color: "light_gray" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-body-2 font-weight-bold",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.brands")))]
                                ),
                              ]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              { attrs: { color: "light_gray" } },
                              _vm._l(_vm.productBrands, function (brand) {
                                return _c(
                                  "div",
                                  { key: brand.value },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        dense: "",
                                        "on-icon": "$checkIcon",
                                        value: brand.uuid,
                                        "hide-details": "",
                                        ripple: false,
                                      },
                                      on: { change: _vm.updateFilter },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize text-body-2 unbox_black--text",
                                                  },
                                                  [_vm._v(_vm._s(brand.text))]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.filter.brands,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filter, "brands", $$v)
                                        },
                                        expression: "filter.brands",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "my-4" }),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "rounded-lg font-weight-bold white--text text-body-2 py-4",
                        attrs: { block: "", color: "unbox_black" },
                        on: { click: _vm.clearFilter },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.clearAllFilters")))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "td",
              [
                _c(
                  "div",
                  { staticClass: "mb-8 d-lg-none d-md-block" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { color: "white" },
                        on: { click: _vm.openFilterDrawer },
                      },
                      [
                        _c("v-icon", {
                          staticClass: "stroke--unbox_primary pr-2",
                          domProps: { textContent: _vm._s("$filterIcon") },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("label.sideFilter")) + " "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "text-right mb-sm-8 mb-4 mt-sm-0 mt-4",
                    attrs: { cols: "12" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "text-body-2 sortBy-selection",
                      attrs: {
                        outlined: "",
                        items: _vm.orderByItem,
                        dense: "",
                        label: _vm.$t("label.sortBy"),
                      },
                      on: { change: _vm.updateFilterSortBy },
                      model: {
                        value: _vm.filter.orderBy,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "orderBy", $$v)
                        },
                        expression: "filter.orderBy",
                      },
                    }),
                  ],
                  1
                ),
                _vm.products != null && _vm.products.length <= 0
                  ? _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "mt-16 pt-16 text-center productContent-noResult-box",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("div", { staticClass: "text-h5 d-block my-2" }, [
                              _vm._v(_vm._s(_vm.$t("label.searchNoResult"))),
                            ]),
                            _c("div", { staticClass: "text-h6 my-2" }, [
                              _vm._v(_vm._s(_vm.$t("label.noMatchSearchTerm"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _vm._l(_vm.products, function (product) {
                          return _c(
                            "v-col",
                            {
                              key: product.group,
                              attrs: { xl: "3", lg: "3", md: "4", cols: "6" },
                            },
                            [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var hover = ref.hover
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "mx-2",
                                              attrs: {
                                                elevation: hover ? 10 : 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goToProductDetailPage(
                                                    product
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "px-sm-6 px-0 pt-2 pb-6 mb-3 mx-2 cursor-pointer",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "full-width",
                                                    attrs: {
                                                      src: product.gallery[0],
                                                      alt: product.name,
                                                      title: product.name,
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-6" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            product.highestDiscountedRate !=
                                                            0
                                                              ? "d-visible"
                                                              : "d-invisible",
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                align: "center",
                                                                "no-gutters":
                                                                  "",
                                                                justify:
                                                                  "space-between",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "5",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-caption text-decoration-line-through text_light_gray--text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$shared.formatCurrency(
                                                                              product.lowestSellingPrice
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  class:
                                                                    product.highestDiscountedRate >=
                                                                    3
                                                                      ? "d-visible"
                                                                      : "d-invisible",
                                                                  attrs: {
                                                                    cols: "4",
                                                                    md: "4",
                                                                    lg: "6",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "white--text text-caption text-uppercase font-weight-bold",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "label.upTo"
                                                                              )
                                                                            ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                product.highestDiscountedRate
                                                                              ) +
                                                                              "%"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-body-2 unbox_black--text productContent-name mt-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(product.name)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-sm-h5 text-h6 font-weight-black unbox_red--text",
                                                        },
                                                        [
                                                          product.discountRate ==
                                                          0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$shared.formatCurrency(
                                                                      product.lowestSellingPrice
                                                                    )
                                                                  )
                                                                ),
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$shared.formatCurrency(
                                                                      product.lowestDiscountedPrice
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-caption text_light_gray--text productContent-description mt-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.description
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-pagination", {
                              attrs: {
                                color: "unbox_primary",
                                length: _vm.paoductPagination.lastPage,
                                "total-visible": 7,
                              },
                              on: { input: _vm.updateFilterPage },
                              model: {
                                value: _vm.filter.page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "page", $$v)
                                },
                                expression: "filter.page",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }